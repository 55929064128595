import pic from "./assets/pic.jpg";
import "./style/App.css";
import Education from "./components/Education";
import Work from "./components/Work";
// import Certifications from "./components/Certifications";
import Theses from "./components/Theses";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="header-container">
      <header class="header"></header>

      <div className="bio">
        <img src={pic} alt="Profile" className="profile-image" />

        <div className="title">Yaman Sanobar</div>
        <div className="intro">
          {" "}
          I am a full-stack developer and an educator with a passion 
           for creating intuitive, user-focused digital solutions. Currently, 
           I teach full-stack development, equipping students with practical skills 
           and guiding them to become confident junior developers. <br /> <br /> My 
           multidisciplinary background allows me to bridge the gap between theoretical 
           research and hands-on development, enabling me to approach challenges with a 
           balanced perspective that includes understanding theory as well as implementing 
           robust, scalable code.
        </div>
        <div className="spacing"></div>
        <div className="spacing"></div>
        <Work />
        <Education />
        <Theses />
        {/* <Certifications /> */}
        <div className="spacing"></div>
        <div className="spacing"></div>
        <div className="spacing"></div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
